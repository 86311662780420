import { css } from "styled-components"
import NotoSansBold from "./NotoSans-Bold.ttf"
import NotoSansItalic from "./NotoSans-Italic.ttf"
import NotoSansRegular from "./NotoSans-Regular.ttf"

export default css`
  @font-face {
    font-family: 'NotoSans';
    src: url(${NotoSansRegular}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'NotoSans';
    src: url(${NotoSansBold}) format('truetype');
    font-weight: Bold;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'NotoSans';
    src: url(${NotoSansItalic}) format('truetype');
    font-weight: normal;
    font-style: italic;
  }
`

