import styled, { createGlobalStyle } from "styled-components"
import reset from "styled-reset"
import { fullWidthHeigth } from "./common"

export const GlobalStyle = createGlobalStyle`
  ${reset}

  ;
  body {
    font-family: 'NotoSans', Arial, sans-serif;
  }

  html {
    ${fullWidthHeigth};
  }

  button {
    border: none;
    outline: none;
  }
`

export const StyleLayout = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);

  > iframe {
    flex: 1;
  }
`
