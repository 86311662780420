import React from "react"
import Layout from "../components/layout"
import { Iframe, RotateYourPhone } from "../assets/styles/home"

const Home = () => {
  return (
    <Layout>
      <Iframe
        src="https://master.d1n84ui6s9kxmp.amplifyapp.com/"
        frameBorder="0"
        allow="microphone *"
      />
      <RotateYourPhone>
        <div />
        <p>Por favor pon el móvil en posición vertical para poder ver correctamente.</p>
      </RotateYourPhone>
    </Layout>
  )
}

export default Home
