import styled from "styled-components"
import { device } from "../styles/size"

export const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
  background-color: #000;
  @media ${device.mobileHorizontal} {
    display: none;
  }
`

export const RotateYourPhone = styled.div`
  display: none;
  width: 100%;
  height: 100%;
  flex: 1;
  background-color: #000;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  > div {
    height: 50px;
    width: 100px;
    margin-bottom: 50px;
    border: 3px solid white;
    border-radius: 10px;
    animation: rotate 1.5s ease-in-out infinite alternate;
  }
  p {
    color: #fff;
  }

  @media ${device.mobileHorizontal} {
    display: flex;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg)
    }
    80% {
      transform: rotate(-90deg)
    }
    100% {
      transform: rotate(-90deg)
    }
  }
`
