import React, { useEffect } from "react"
import Header from "./Header"
import { GlobalStyle, StyleLayout } from "../assets/styles/global"
import "../assets/fonts"

const Layout = ({ children }) => {
  useEffect(() => {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    const vh = window.innerHeight * 0.01
// Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`)

    window.addEventListener('resize',()=>{
      const vh = window.innerHeight * 0.01

      document.documentElement.style.setProperty("--vh", `${vh}px`)
    })
  }, [])

  return (
    <StyleLayout>
      <GlobalStyle />
      <Header />
      {children}
      <footer />
    </StyleLayout>
  )
}

export default Layout
