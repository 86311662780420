import styled, { css } from "styled-components"
import { colors, Container, resetBtn } from "../../assets/styles/common"
import Logo from "../../assets/gfx/logo.svg"
import CloseIcon from "../../assets/gfx/close.svg"
import { device } from "../../assets/styles/size"

export const StyledHeader = styled.header`
  box-sizing: border-box;
  position: relative;
  height: 100px;
  display: flex;
  align-items: center;
  @media ${device.tablet} {
    height: 120px;
  }
  @media ${device.laptopL} {
    height: 160px;
  }
`

export const HeaderWrapper = styled(Container)`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
`

export const LogoStyled = styled(Logo)`
  width: 150px;
  @media ${device.tablet} {
    width: 200px;
  }
  @media ${device.laptopL} {
    width: 270px;
  }
`

export const MenuButton = styled.button`
  ${resetBtn};
  width: 35px;
  height: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media ${device.tablet} {
    width: 45px;
    height: 30px;
  }
  span {
    top: 0;
    position: absolute;
    width: 100%;
    height: 3px;
    background-color: ${colors.green};
    transition: .4s;
    &:nth-child(2) {
      top: 50%;
    }
    &:nth-child(3) {
      top: 100%;
    }    
    ${props => props.opened && css`
      &:nth-child(1),&:nth-child(3){
        top: 50%;
        transform: rotate(90deg);
      }
    `}
  }
`

const InIframeStyles = css`
  width: 100%;
  left: 0;
  @media ${device.tablet} {
    position: absolute;
    left: unset;
    top: 120px;
  }
  @media ${device.laptopL} {
    top: 160px;
  }
`

export const RightContentStyles = css`
  ${InIframeStyles};
  box-sizing: border-box;
  @media ${device.tablet} {
    width: 330px;
    margin: 3px 30px 0 0;
    right: 0;
  }
`

export const LeftContentStyles = css`
  font-size: 16px;
  line-height: 1.7;
  letter-spacing: -.5px;
  @media ${device.tablet} {
    font-size: 22px;
    color: #fff;
    position: fixed;
    left: 40px;
  }
  @media ${device.mobileHorizontal} {
    color: #fff;
    position: fixed;
    left: 40px;
  }
`

export const SectionContainer = styled.div`
  position: relative;
  @media ${device.tablet} {
    position: unset;
  }
`

export const SectionHeading = styled.h2`
  ${RightContentStyles};
  cursor: pointer;
  padding: 10px 30px;
  background-color: ${colors.green};
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  @media ${device.tablet} {
    font-size: 20px;
  }
`

export const CloseButton = styled(CloseIcon)`
  width: 20px;
  height: 20px;
  position: absolute;
  right: 20px;
  @media ${device.tablet} {
    right: 10px;
  }
`

export const SectionBody = styled.div`
  ${LeftContentStyles};
  padding: 10px 70px 10px 30px;
  position: relative; 
  @media ${device.tablet} {
    padding: 0;
    top: 220px;
    max-width: 30vw;
  }
  @media ${device.laptopL} {
    top: 300px;
  }
  @media ${device.mobileHorizontal} {
    padding: 0;
    top: 160px;
    max-width: 30vw;
  }
  > div {
    max-height: 150px;
    overflow-y: auto;
    scroll-behavior: smooth;
    -ms-overflow-style: none;  /* IE and Edge */      
    ::-webkit-scrollbar {
      display: none;
    }
    @media ${device.tablet} {
      max-height: calc(100vh - 450px);    
    }
    @media ${device.mobileHorizontal} {
      max-height: calc(100vh - 200px);
    }
  }
  a {
    text-decoration: none;
    font-weight: bold;
    color: ${colors.green}
  }
  strong {
    font-weight: bold;
    color: ${colors.green};
  }
  button {
    ${resetBtn};
    width: 30px;
    height: 30px;
    transition: .4s;
    position: absolute;
    right: 20px;
    bottom: 10px;
    @media ${device.tablet} {
      width: 40px;
      height: 40px;
      margin-top: 20px;
      position: relative;
      right: unset;
      bottom: unset;
    }
    @media ${device.mobileHorizontal} {
      bottom: -20px;
    }

      ${props => props.scrollDirection === "up" && css`
      transform: rotate(180deg);
    `};
  }
  img {
    margin-top: 20px;
  }
  ul {
    margin: 10px 0;
    li {
      margin: 5px 0;
      padding-left: 30px;
      position: relative;
      
      &:before{
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 2px;
        height: 22px;
        width: 11px;
        background: url('/gfx/bullet.svg') left center no-repeat;
        background-size: cover;
        @media ${device.tablet} {
          height: 26px;
          width: 13px;
          top: 8px;
        }
      }
    }
  }
`

export const PreguntameMas = styled.div`
  ${LeftContentStyles};
  font-style: italic;
  padding: 0 15px;
  position: absolute;
  bottom: -40px;
  color: #fff;
  @media ${device.tablet} {
    padding: 0;
    top: unset;
    bottom: 50px;
    max-width: 40vw;
  }
`
