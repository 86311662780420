import React from "react"
import { MenuDrawerStyled, MenuScrollButton, MenuItem, ScrollableWrapper } from "./menuDrawerStyled"
import ScrollDown from "../../assets/gfx/scroll-down.svg"
import { useScroll } from "../../hooks/useScroll"

const MenuDrawer = (props) => {
  const {
    menuItems,
    menuOpened, setMenuOpened,
    setBodyOpened,
    sectionSelected, setSectionSelected
  } = props

  const [ drawerSectionRef, scrollDrawer, checkScroll, scrollDirection ] = useScroll()

  return (
    <MenuDrawerStyled
      duration={500}
      height={menuOpened ? "auto" : 0}
      onAnimationEnd={() => !menuOpened && setBodyOpened(true)}
    >
      <ScrollableWrapper
        ref={drawerSectionRef}
        onScroll={checkScroll}
      >
        {
          menuItems.map(menuItem => (
            <MenuItem
              key={menuItem.id}
              selected={sectionSelected === menuItem.id}
              onClick={() => {
                setBodyOpened(false)
                setSectionSelected(menuItem.id)
                setMenuOpened(false)
              }}
            >
              {menuItem.title}
            </MenuItem>)
          )
        }
        <MenuScrollButton
          scrollDirection={scrollDirection}
          onClick={() => scrollDrawer()}
        >
          <ScrollDown />
        </MenuScrollButton>
      </ScrollableWrapper>
    </MenuDrawerStyled>
  )
}

export default MenuDrawer
