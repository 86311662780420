import { useRef, useState } from "react"

export const useScroll = () => {
  const [ scrolled, setScrolled ] = useState(0)
  const [ scrollDirection, setScrollDirection ] = useState("down")

  const ref = useRef(null)

  const scrollRef = (scrollTo = null) => {
    const { clientHeight } = ref.current
    if (scrollTo === 0) setScrollDirection("down")
    const newScrolled = scrollTo !== null ? scrollTo : (scrollDirection === "down" ? scrolled + clientHeight : 0)
    ref.current.scrollTo(0, newScrolled)
    setScrolled(newScrolled)
  }

  const checkScroll = () => {
    const { scrollHeight, scrollTop, clientHeight } = ref.current
    setScrolled(scrollTop)
    if (scrollHeight - scrollTop === clientHeight) {
      setScrollDirection("up")
    } else {
      setScrollDirection("down")
    }
  }

  return [ ref, scrollRef, checkScroll, scrollDirection ]
}