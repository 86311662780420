import styled, { css } from "styled-components"
import { device } from "./size"

export const colors = {
  green: "#32F06E"
}

export const fullWidthHeigth = css`
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
  @media ${device.tabletL} {
    height: 100vh;  
  }
`

export const resetBtn = css`
  cursor: pointer;
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  
  background: transparent;
  
  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;
  
  /* Normalize \`line-height\`. Cannot be changed from \`normal\` in Firefox 4+. */
  line-height: normal;
  
  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  
  /* Corrects inability to style clickable \`input\` types in iOS */
  -webkit-appearance: none;
  
  &:hover,&:focus {
    background: none;
  }
  
  &:focus {
    outline: none;
  }
  
  &:active {
      //transform: none;
  }
`

export const Container = styled.div`
  margin: 0 auto;
  padding: 0 15px;
  @media ${device.tablet} {
    padding: 0 30px;
  }
  @media ${device.laptopL} {
  }
`