import React, { useEffect, useState } from "react"
import {
  CloseButton, HeaderWrapper, LogoStyled,
  MenuButton,
  PreguntameMas,
  SectionBody, SectionContainer,
  SectionHeading,
  StyledHeader
} from "./headerStyled"
import ScrollDown from "../../assets/gfx/scroll-down.svg"
import MenuDrawer from "../MenuDrawer"
import { useScroll } from "../../hooks/useScroll"
import AnimateHeight from "react-animate-height"

const Header = () => {
  const [ menuOpened, setMenuOpened ] = useState(false)
  const [ bodyOpened, setBodyOpened ] = useState(false)
  const [ bodyScrollable, setBodyScrollable ] = useState(false)
  const [ sectionSelected, setSectionSelected ] = useState(null)
  const [ imageLoaded, setImageLoaded ] = useState(false)

  const menuItems = [
    {
      id: 1,
      title: "Quienes somos",
      body: <>Somos la primera empresa europea especializada en el desarrollo de <strong>humanos digitales</strong> con
              inteligencia artificial y emocional.</>
    },
    {
      id: 2,
      title: "Nuestro propósito",
      body: <>En un mundo cada vez más digitalizado nuestro pospuesto es <strong>humanizar</strong> cualquier relación
              digital entre
              humanos y marcas, empresas, corporaciones, instituciones y personas</>
    },
    {
      id: 3,
      title: "Qué hacemos",
      body: <>Transformamos en tiempo real
              cualquier <strong>inteligencia artificial</strong> conversacional en inteligencia
              emocional con expresiones
              humanas no verbales.</>
    },
    {
      id: 4,
      title: "Descubre U-MANS",
      body: <>Descubre la primera <strong>interfaz humana webGL</strong> con inteligencia artificial y emocional
              sincronizadas en tiempo
              real. <br />
              Presentamos U-MANS los primeros <strong>humanos digitales</strong> inteligentes y empáticos en tiempo
              real.</>
    },
    {
      id: 5,
      title: "Casos de uso y aplicaciones",
      body: <>
        Nuestro software se integra con cualquier plataforma de AI, Watson Dialogflow, Luis, … y al ser webGL tiene
        el peso de una página web, por lo que funciona en cualquier entorno digital sin necesidad de descargarse
        ninguna aplicación.
        <ul>
          <li>Personal shopper multilingue en e-commerce.</li>
          <li>Asistente personal multilingue en web y app.</li>
          <li>Atención a cliente empática 24/7 en web, app y call center.</li>
          <li>Interfaz human AI, empática y eficiente en ERP y CRM.</li>
          <li>Asistente virtual digital AI empático y
              multilingual en Retail
          </li>
        </ul>
      </>
    },
    {
      id: 6,
      title: "Reconocimientos",
      body: <>Hemos sido nombrados Pyme Innovadora del 2020 por el Ministerio de Innovación y Ciencia del Gobierno de
              España.<br /><img onLoad={() => setImageLoaded(!imageLoaded)} src='/gfx/logo-pyme.png' title='Pyme Innovadora' alt='Ptme Innovadora' /></>
    },
    // {
    //   id: 7,
    //   title: "Clientes",
    //   body: <>
    //     <ul>
    //       <li>Listado:</li>
    //       <li>Cliente 1</li>
    //       <li>Cliente 2</li>
    //       <li>Cliente 3</li>
    //       <li>Cliente 4</li>
    //     </ul>
    //   </>
    // },
    {
      id: 8,
      title: "Inversores",
      body: <>Nuestra idea de inversor es alguien con el que vamos a <strong>compartir un viaje</strong>. Por eso
              buscamos
              inversores que
              más allá de aportar la financiación, les apasione el proyecto y se conviertan en nuestros partners.</>
    },
    {
      id: 9,
      title: "Contacta con nosotros",
      body: <>Escríbenos a <a href="mailto:hola@wetalkai.com">hola@wetalkai.com</a></>,
      preguntame: false
    }
  ]

  const [ bodySectionRef, scrollBody, checkScroll, scrollDirection ] = useScroll()

  useEffect(() => {
    if (bodySectionRef?.current) {
      const { clientHeight, scrollHeight } = bodySectionRef.current

      if (scrollHeight > clientHeight) {
        setBodyScrollable(true)
      } else {
        setBodyScrollable(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ sectionSelected, imageLoaded ])

  useEffect(() => {
    if (bodySectionRef?.current) {
      scrollBody(0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ sectionSelected ])

  return (
    <>
      <StyledHeader>
        <HeaderWrapper>
          <LogoStyled />
          <MenuButton onClick={() => setMenuOpened(!menuOpened)} opened={menuOpened}>
            <span /><span /><span />
          </MenuButton>
        </HeaderWrapper>
      </StyledHeader>
      <AnimateHeight
        duration={500}
        height={(bodyOpened && sectionSelected && (window.innerWidth > 767 || !menuOpened)) ? "auto" : 0}
        onAnimationEnd={() => setImageLoaded(!imageLoaded)}
      >
        {
          sectionSelected ? <SectionContainer>
            <SectionHeading
              onClick={() => setSectionSelected(null)}
            >
              {menuItems.filter(i => i.id === sectionSelected)[0].title}
              <CloseButton />
            </SectionHeading>
            <SectionBody
              scrollDirection={scrollDirection}
            >
              <div
                ref={bodySectionRef}
                onScroll={checkScroll}
              >
                {
                  menuItems.filter(i => i.id === sectionSelected)[0].body
                }
              </div>
              {
                bodyScrollable &&
                <button
                  onClick={() => scrollBody()}
                >
                  <ScrollDown />
                </button>
              }
            </SectionBody>
            {
              menuItems.filter(i => i.id === sectionSelected)[0].preguntame !== false && <PreguntameMas>
                ¿Quiéres saber más, pregúntame?
              </PreguntameMas>
            }
          </SectionContainer> : <div />
        }
      </AnimateHeight>
      <MenuDrawer
        menuItems={menuItems}
        menuOpened={menuOpened}
        bodyOpened={bodyOpened}
        setBodyOpened={setBodyOpened}
        setMenuOpened={setMenuOpened}
        sectionSelected={sectionSelected}
        setSectionSelected={setSectionSelected}
      />
    </>
  )
}

export default Header
