import styled, { css } from "styled-components"
import { colors, resetBtn } from "../../assets/styles/common"
import { device } from "../../assets/styles/size"
import AnimateHeight from "react-animate-height"
import { RightContentStyles } from "../Header/headerStyled"

export const MenuDrawerStyled = styled(AnimateHeight)`
  ${RightContentStyles};
  padding: 0;
  background-color: #fff;
`

export const ScrollableWrapper = styled.div`
  max-height: 195px;
  overflow-y: auto;
  scroll-behavior: smooth;
  -ms-overflow-style: none;  /* IE and Edge */      
  ::-webkit-scrollbar {
    display: none;
  }
  @media ${device.tablet} {
    max-height: unset;  
  }
`

export const MenuItem = styled.button`
  ${resetBtn};
  display: block;
  width: 100%;
  text-align: left;
  padding: 5px 20px;
  color: #000;
  font-weight: bold;
  &:hover {
    background-color: ${colors.green};
    color: #fff;
  }
  ${props => props.selected && css`
    background-color: ${colors.green} !important;
    color: #fff !important;
  `};
  @media ${device.tablet} {
    font-weight: normal;
    &:first-child {
      margin-top: 20px;
    }
    &:last-child{
      margin-bottom: 10px;
    }
  }
`

export const MenuScrollButton = styled.button`
  ${resetBtn};
  position: absolute;
  top: 250px;
  right: 20px;
  width: 30px;
  height: 30px;
  transition: .4s;
  ${props => props.scrollDirection === "up" && css`
    transform: rotate(180deg);
  `};
  @media ${device.tablet} {
    display: none;
  }
`